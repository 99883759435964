import { css } from '@emotion/react';

const globalStyles = (theme) => css`
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	html,
	body {
		-webkit-overflow-scrolling: touch;
	}

	body {
		overflow: unset;
	}

	html,
	body,
	#___gatsby,
	#gatsby-focus-wrapper,
	#gatsby-focus-wrapper > * {
		height: 100%;
	}

	div[role='group'][tabindex] {
		height: 100%;
	}

	button {
		outline: none;
		border: none;
		font-family: inherit;
	}

	a {
		text-decoration: none;
	}

	body {
		margin: 0;
		-webkit-text-size-adjust: 100%;
		font-family: ${theme.typography.body1.fontFamily};
	}
`;

export default globalStyles;
