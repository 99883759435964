import { css } from '@emotion/react';

export default (theme) => css`
	display: flex;
	flex-direction: column;
	height: 100%;

	.content-wrapper {
		flex-grow: 1;
		display: flex;
		flex-direction: column;

		.content {
			flex-grow: 1;

			&.content-disable-gutter-bottom {
				padding-bottom: 0;
			}

			&.content-disable-gutter-top {
				padding-top: 0;
			}
		}
	}
`;
