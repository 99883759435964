import { navigate } from 'gatsby';
import { CARD_PUBLIC, VCARD } from '@helpers/api';
import { makeGetRequest } from '@helpers/requests';
import React, { useState, useEffect } from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import { useSnackbar } from '@components/common/Snackbar';
import { useTheme } from '@mui/styles';
import { Card as CardType } from '@models/card';
import Public from '@components/common/Layouts/Public';
import Loader from '@components/common/Loader';

const ContactCard: React.FC<RouteComponentProps<{ id: string }>> = ({ id }) => {
	const [openSnackbar] = useSnackbar();
	const theme = useTheme();
	const [loading, setLoading] = useState<boolean>(true);
	const [card, setCard] = useState<CardType | null>(null);

	useEffect(() => {
		(async () => {
			try {
				if (!id) {
					// TODO: Redirect user away to a more ideal page than a snackbar error.
					openSnackbar('No Contact Card was given.', 'error');
					return;
				}

				const { data: cardData } = await makeGetRequest(CARD_PUBLIC(id));

				setCard(cardData);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve this card.',
						'error'
					);
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (!card || card.mode !== 'vCard') return;
		navigate(`${VCARD(card.vCardId)}/download`);
	}, [card]);

	return (
		<Public>
			<Loader />
		</Public>
	);
};

const ContactCardRoute: React.FC = () => {
	return (
		<Router>
			<ContactCard path="/contact-card/:id" />
		</Router>
	);
};

export default ContactCardRoute;
