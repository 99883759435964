import React, { useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import globalStyles from '@globalStyles';
import styles from './styles';
import { useTheme } from '@mui/styles';
import Header from '@components/common/Layouts/Basic/Header';

const Public: React.FC<{
	inversedHeader?: boolean;
	disableGutterTop?: boolean;
	disableGutterBottom?: boolean;
	flush?: boolean;
}> = ({ children, inversedHeader, flush, disableGutterTop, disableGutterBottom }) => {
	const theme = useTheme();

	return (
		<>
			<Global styles={globalStyles(theme)} />
			<div css={styles(theme)}>
				<main
					className={`content-wrapper`}
				>
					<div
						className={`content ${
							disableGutterTop ? 'content-disable-gutter-top' : ''
						} ${disableGutterBottom ? 'content-disable-gutter-bottom' : ''}`}
					>
						{children}
					</div>
				</main>
			</div>
		</>
	);
};

export default Public;
