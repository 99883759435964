import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

const Loader: React.FC<{ tip?: string; innerRef: any; color: string }> = ({
	tip,
	innerRef,
	color,
}) => {
	const theme = useTheme();
	return (
		<Box
			ref={innerRef}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			height="100%"
			width="100%"
		>
			<CircularProgress sx={{ color: color ?? theme.palette.primary.main }} />

			{!!tip && (
				<>
					<Box mt={2} />
					<Typography variant="body2">{tip}</Typography>
				</>
			)}
		</Box>
	);
};

export default React.forwardRef((props, ref) => <Loader {...props} innerRef={ref} />);
